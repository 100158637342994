<template>
  <div>
    <!-- {{ user }} -->
    <a-row type="flex" :gutter="24" justify="center" class="p-3">
      <a-col :span="10">
        <h2 class="font-weight-bold">Edit Profile</h2>
        <!-- <div>
          <div>
            <div class="font-weight-bold">username</div>
            <div>{{ user.username }}</div>
          </div>

          <div>
            <div class="font-weight-bold">email</div>
            <div>{{ user.email }}</div>
          </div>

          <div v-if="user.role === 'admin' && user.organization">
            <div class="font-weight-bold">organization</div>
            <div>{{ user.organization.name }}</div>
          </div>
        </div> -->
      </a-col>
      <a-col :span="10">
        <div class="d-flex justify-content-center align-items-center">
          <a-avatar v-if="user.avatar" :size="72" :src="user.avatar" />
          <a-avatar
            v-else
            :size="72"
            src="https://www.globish.co.th/front/img/globish-logo-temp.png"
          />
        </div>
        <a-form layout="vertical">
          <a-form-item label="username">
            <a-input v-model="user.username" disabled />
          </a-form-item>
          <a-form-item label="email">
            <a-input v-model="user.email" disabled />
          </a-form-item>
          <a-form-item
            v-if="user.role === 'admin' && user.organization"
            label="organization"
          >
            <a-input v-model="user.organization.name" disabled />
          </a-form-item>
          <a-form-item label="fullname">
            <a-input v-model="user.fullname" />
          </a-form-item>
          <a-form-item label="image url">
            <a-input v-model="user.avatar" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="updateProfile">Update</a-button>
          </a-form-item>
        </a-form>
        <p>
          If you want to change password
          <router-link :to="{ name: 'reset-password' }" class="reset-password">
            click here.
          </router-link>
        </p>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { get } from "@/store/pathify-helper";
import AdminService from "@/services/admin.service";
export default {
  name: "Profile",
  setup(props, context) {
    const user = get(context, "auth/user");
    return { user };
  },
  methods: {
    async updateProfile() {
      const { fullname, avatar } = this.user;
      const payload = {
        fullname,
        avatar: avatar
          ? avatar
          : "https://www.globish.co.th/front/img/globish-logo-temp.png"
      };
      const { status } = await AdminService.editProfile(payload);
      if (status === 200) {
        this.$message.success("Updated Successfull");
      } else {
        // TODO: display update admin error
        this.$message.error("Updated Fail");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.reset-password {
  color: $primary-color;
  &:hover {
    color: $primary-color;
    text-decoration: underline;
  }
}
</style>
